import { FetchPromise } from "@reservauto/react-shared/services/ServiceBase";
import errorStore from "@reservauto/react-shared/stores/errorStore";
import StateStoreBase from "@reservauto/react-shared/stores/StateStoreBase";
import { CityDTO, EBranch, ECity } from "../../areas/general/dto";
import { CityService } from "../../areas/general/service";
import branchStore from "./branchStore";

class CitiesStore extends StateStoreBase<CityDTO[]> {
  private cities: CityDTO[] | null = null;
  private isLoaded = false;
  private loadedForBranchId: number | null = null;

  public get(): CityDTO[] {
    return this.cities ?? [];
  }

  public getCity(cityId: number): ECity | null {
    return this.cities?.find((c) => c.cityId === cityId)?.city ?? null;
  }

  public getCityBranch(city: ECity): EBranch | null {
    return this.cities?.find((c) => c.city === city)?.cityBranch ?? null;
  }

  public getCityId(city: ECity): number | null {
    return this.cities?.find((c) => c.city === city)?.cityId ?? null;
  }

  public populate(branchId: number | null = null): FetchPromise<CityDTO[]> {
    const currentBranchId = branchId ?? branchStore.get().branchId;

    if (this.isLoaded && currentBranchId === this.loadedForBranchId) {
      return new FetchPromise<CityDTO[]>((resolve) => resolve(this.get()));
    }

    this.isLoaded = true;
    this.loadedForBranchId = currentBranchId;

    return new CityService()
      .getCity({
        branch: currentBranchId as unknown as EBranch,
        onAbort: () => (this.isLoaded = false),
        returnCitiesOfAssociatedBranches: true,
      })
      .then((result) => {
        this.cities = result.cities;

        this.notifySubscribers();
        return this.get();
      })
      .catch((ex) => {
        errorStore.showExceptionPage(ex);
        return this.get();
      });
  }
}

const cityStore = new CitiesStore();
export default cityStore;
